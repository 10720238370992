import ApiService from './api.service'

const ProductService = {
  async products(data) {
    try {
      return await ApiService.get(
        `admin/products?page=${data.page}&archived=${data.archived}`,
      )
    } catch (error) {
      return error
    }
  },
  async productsCategory(id) {
    try {
      return await ApiService.get('categories/' + id + '/products')
    } catch (error) {
      return error
    }
  },
  async product(id) {
    try {
      return await ApiService.get('products/' + id + '/show/langs')
    } catch (error) {
      return error
    }
  },

  async filterProducts(data) {
    try {
      return await ApiService.get(`products/filter?page=1${data}`)
    } catch (error) {
      return error
    }
  },

  async fetchUserOpenCart(userId) {
    try {
      return await ApiService.get(`cart/open/${userId}`)
    } catch (error) {
      return error
    }
  },

  async addItem(productData) {
    try {
      if (productData?.token) {
        return await ApiService.put(
          'cart/item/' + productData.token,
          productData,
        )
      }
      return await ApiService.put('cart/item', productData)
    } catch (e) {
      return e.response
    }
  },

  async deleteCartItem(token) {
    try {
      return await ApiService.delete('cart/item/' + token)
    } catch (e) {
      return e.response
    }
  },

  async updateItemQty(data) {
    try {
      return await ApiService.put('cart/quantity/update', data)
    } catch (e) {
      return e.response
    }
  },

  async hashData(data) {
    try {
      return await ApiService.get(`hash/${data}`)
    } catch (error) {
      return error
    }
  },

  async createOrder(data) {
    try {
      return await ApiService.post('orders', data)
    } catch (e) {
      return e.response
    }
  },

  async fetchProductVariants(productId) {
    try {
      return await ApiService.get(`products/variants/${productId}`)
    } catch (error) {
      return error
    }
  },

  async copyVariant(productId, data) {
    try {
      return await ApiService.post(`products/variants/${productId}/copy`, data)
    } catch (e) {
      return e.response
    }
  },

  async loadProductDealersUsers() {
    try {
      const response = await ApiService.get('products/dealers/select')
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },

  async importProducts(data) {
    try {
      const response = await ApiService.post('products/import', data)
      if (response.status == 200) {
        return response.data
      }
    } catch (error) {
      return error.response
    }
  },

  async newProduct(data) {
    try {
      return await ApiService.post('products', data)
    } catch (error) {
      return error
    }
  },

  async saveProduct(data, id) {
    try {
      return await ApiService.put('products/' + id, data)
    } catch (error) {
      return error
    }
  },

  async productsSelect(withHashId = false) {
    try {
      return await ApiService.get(
        `products/select${withHashId ? '?withHashId=true' : ''}`,
      )
    } catch (error) {
      return error
    }
  },

  async importedProducts(data = { page: 1, search: null }) {
    try {
      return await ApiService.get(
        `products/imported?page=${data.page}&search=${data.search}`,
      )
    } catch (error) {
      return error
    }
  },

  async archiveProducts(id) {
    try {
      return await ApiService.post(`products/archive/${id}`)
    } catch (error) {
      return error
    }
  },
}

export { ProductService }
