import ApiService from './api.service'

const ContactService = {
  async create(data) {
    try {
      return await ApiService.post('admin/contact-service', data)
    } catch (error) {
      return error.response
    }
  },
  async list(page = 1) {
    try {
      return await ApiService.get('admin/contact-services?page=' + page)
    } catch (error) {
      return error.response
    }
  },
  async delete(id) {
    try {
      const response = await ApiService.delete(`admin/contact-service/${id}`)
      return response
    } catch (error) {
      return error.response.data
    }
  },
  async show(id) {
    try {
      return await ApiService.get(`admin/contact-service/${id}`)
    } catch (error) {
      return error.response
    }
  },
  async update(data) {
    try {
      return await ApiService.put(`admin/contact-service/${data.id}`, data)
    } catch (error) {
      return error.response
    }
  },
  async reorder(data) {
    try {
      return await ApiService.post('admin/contact-service/reorder', data)
    } catch (error) {
      return error
    }
  },
  async createDescription(data) {
    try {
      return await ApiService.post('admin/contact-service-description', data)
    } catch (error) {
      return error.response
    }
  },
  async showDescription() {
    try {
      return await ApiService.get(`contact-service-description`)
    } catch (error) {
      return error.response
    }
  }
}

export { ContactService }
