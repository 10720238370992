import ApiService from './api.service'

const SupplierService = {
  async suppliersSelect() {
    try {
      return await ApiService.get('suppliers/select')
    } catch (error) {
      return error
    }
  },
  async importSupplierProducts(data) {
    try {
      return await ApiService.get(
        `products/import/supplier/${data.supplierId}?search=${data.search}`,
      )
    } catch (error) {
      return error
    }
  },
  async storeSupplier(data) {
    try {
      return await ApiService.post('suppliers', data)
    } catch (error) {
      return error
    }
  },
  async deleteSupplier(id) {
    try {
      return await ApiService.delete(`suppliers/${id}`)
    } catch (error) {
      return error
    }
  },
  async suppliers() {
    try {
      return await ApiService.get('suppliers')
    } catch (error) {
      return error
    }
  },
}

export { SupplierService }
