import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import { TENANT_GROUPS } from '@/config'
import store from '../store'
import mainRoutes from '@/router/main'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/pages/Login')
  },
  {
    path: '/dashboard',
    name: 'home',
    component: DefaultLayout,
    children: [...mainRoutes]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  // Check required role
  let requiredRole = to.meta.requiredRole
  let requiredLang = to.meta.requiredLang
  const lang = localStorage.getItem('lang')

  if (requiredRole) {
    try {
      await store.dispatch('me')
      let user = store.getters.getAdminRole
      let role = user.find((item) => requiredRole.includes(item.name))

      // role doesnt match
      if (!role) {
        next('/dashboard')
        return
      }

      const allowedTenantGroups = to.meta.allowedTenant || []
      const isAllowed = allowedTenantGroups.some((group) => {
        const tenants = TENANT_GROUPS[group] || []
        return tenants.includes(store.getters.tenantId)
      })

      if (isAllowed) {
        next() // Allow access to the route
      } else {
        //next('/not-authorized'); // Redirect or handle unauthorized access
        next('/dashboard')
      }
      return

    } catch (e) {
      console.log(e)
      next('/dashboard')
      return
    }
  }
  next()
  return
})

export default router
