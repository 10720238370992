const mutations = {
  SET_IMPORTER_TYPE(state, type) {
    state.importerType = type
  },
  SET_IMPORTER_TYPES(state, types) {
    state.importerTypes = types
  },
}

export { mutations }
