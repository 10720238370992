export default {
  message: {
    'points-importer': {
      label: 'File with points',
      create: 'Upload',
      'importer-type': 'Import type',
      'import-name': 'Import name',
      table: {
        index: '',
        customerNumber: 'Customer number',
        pointsInList: 'Already existing points',
        user: 'Customer',
        points: 'Points from file',
        adds: 'New points',
      },
      alert: {
        success: 'Points successfully imported',
      },
    },
    'points-importer-types': {
      list: {
        table: {
          name: 'Name',
          description: 'Description',
        },
      },
    },
    menu: {
      'points-importer': 'Points importer',
      'points-importer-config-importers': 'Configuratopm',
      'points-importer-import': 'Import',
    },
    breadcrumb: {
      'points-importer': 'Points importer',
      'points-importer-config-importers': 'Configuratopm',
      'points-importer-config-edit-importer': 'Edit importers',
      'points-importer-import': 'Import',
    },
  },
}
