// Vue core imports
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Configuration and service imports
import { TENANCY_SETTINGS } from '@/config'
import API from './config'
import ApiService from './services/api.service'

// Third-party library imports
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue3Toastify, { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

// Plugin imports
import i18n from '@/plugins/i18n'
import PointsImporter from '@/plugins/points-importer'

// Component imports
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'

// Utility function to extract country code from URL
const extractCountryCode = (url) => {
  const countryMatch = url.match(/https:\/\/(?:dev)?admin-plus-([a-z]{2})\.bsplus\.bridgestone\.eu/i)
  return countryMatch && countryMatch[1] ? countryMatch[1].toUpperCase() : null
}

// Function to load tenant configuration based on country code
const loadTenantConfig = async (countryCode) => {
  try {
    await store.dispatch('loadTenantConfig', countryCode)
    console.log('Loaded Tenant Config from Store:', {
      liteVersion: store.getters.isLiteVersion,
      availableModules: store.getters.availableModules,
      tenancyEnabled: store.getters.tenancyEnabled,
      tenantId: store.getters.tenantId
    })
  } catch (error) {
    console.error('Error loading tenant configuration from store for:', countryCode, error)
  }
}

// Function to detect country and load tenant configuration
const detectCountryAndLoadConfig = async () => {
  let url = window.location.href
  let countryCode = null
  if (TENANCY_SETTINGS.TENANCY) {
    if (TENANCY_SETTINGS.TENANCY_LOCAL) {
      countryCode = TENANCY_SETTINGS.TENANCY_COUNTRY
    } else {
      countryCode = extractCountryCode(url)
      if (countryCode) {
        console.log('Detected Country Code:', countryCode) // Log country code to validate extraction
      }
    }
    await loadTenantConfig(countryCode)
  }
}

// Detect country and load tenant configuration
detectCountryAndLoadConfig().then(() => {
  // Initialize API Service
  ApiService.init(API.URL)

  // Create Vue app and use necessary plugins
  const app = createApp(App)

  // Register store, router, and i18n
  app.use(store)
  app.use(router)
  app.use(i18n)

  // Register UI libraries and components
  app.use(CoreuiVue)
  app.use(VueAxios, axios)
  app.component('CIcon', CIcon)
  app.component('DocsCallout', DocsCallout)
  app.component('DocsExample', DocsExample)

  // Register additional plugins
  app.use(Vue3Toastify, {
    autoClose: 3000,
    position: toast.POSITION.TOP_RIGHT,
    transition: toast.TRANSITIONS.SLIDE,
  })
  app.use(PointsImporter, {store, i18n})

  // Provide icons globally
  app.provide('icons', icons)

  // Mount the app
  app.mount('#app')
}).catch((error) => {
  console.error('Error during initialization:', error)
})
