const trainings = [
  {
    path: 'trainings',
    name: 'trainings',
    icon: 'cil-list-high-priority',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['trainingGroup'],
    },
    children: [
      {
        path: '',
        name: 'trainings_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/training/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['trainingGroup'],
        },
      },
      {
        path: 'new',
        name: 'training_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/training/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['trainingGroup'],
        },
      },
      {
        path: ':id',
        name: 'training',
        components: {
          content: () => import('@/views/pages/training/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['trainingGroup'],
        },
      },
    ],
  },
  {
    path: 'btrainings',
    name: 'btrainings',
    icon: 'cil-list-high-priority',
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['trainingGroup'],
    },
    children: [
      {
        path: '',
        name: 'btrainings_list',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/btraining/List.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['trainingGroup'],
        },
      },
      {
        path: 'new',
        name: 'btraining_new',
        showInMenu: true,
        components: {
          content: () => import('@/views/pages/btraining/New.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['trainingGroup'],
        },
      },
      {
        path: ':id',
        name: 'btraining',
        components: {
          content: () => import('@/views/pages/btraining/Edit.vue'),
        },
        meta: {
          requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
          requiredLang: ['de', 'ch'],
          allowedTenant: ['trainingGroup'],
        },
      },
    ],
  },
]

export default trainings
