const actions = {
  setImporterType({ commit, getters }, type) {
    commit(
      'SET_IMPORTER_TYPE',
      getters.importerTypes.find((importer) => importer.id === type),
    )
  },
  setImporterTypes({ commit }, response) {
    commit('SET_IMPORTER_TYPES', response.data)
  },
}

export { actions }
