const surveys = [
  {
    path: '',
    name: 'surveys_list',
    icon: 'cil-short-text',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/hello-customer-surveys/List.vue'),
    },
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['nl', 'en'],
      allowedTenant: ['surveyGroup'],
    },
  },

  {
    path: 'new',
    name: 'survey_new',
    icon: 'cil-short-text',
    showInMenu: true,
    components: {
      content: () => import('@/views/pages/hello-customer-surveys/New.vue'),
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['nl', 'en'],
      allowedTenant: ['surveyGroup'],
    },
  },
  {
    path: ':id',
    name: 'survey',
    components: {
      content: () => import('@/views/pages/hello-customer-surveys/Edit.vue'),
    },
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['nl', 'en'],
      allowedTenant: ['surveyGroup'],
    },
  },
]

export default surveys
