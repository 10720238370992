<template>
  <template v-if="link.children && !link.showInMenu && hasPermission(link)">
    <CNavGroup :visible="checkLink(link) && hasPermission(link)">
      <template #togglerContent>
        <CIcon
          v-if="link?.icon"
          customClassName="nav-icon"
          :icon="link?.icon"
        />
        <CNavItem>
          {{ $t('message.menu.' + link.name) }}
          <CButton
            color="info"
            shape="rounded-pill"
            v-if="link.name === 'requests' || link.name === 'orders_list'"
            >{{ statsCount }}</CButton
          >

          <CButton
            color="info"
            shape="rounded-pill"
            v-if="
              (link.name === 'products-categories' || link.name === 'orders') &&
              currentLang == 'de'
            "
            >{{ countStat('orders') }}</CButton
          >

          <CButton
            color="info"
            shape="rounded-pill"
            v-if="link.name === 'users' && countStat('inactive_users')"
            >{{
              countStat('inactive_users') +
              countStat('customer_numbers_to_approve')
            }}</CButton
          >
        </CNavItem>
      </template>
      <template v-for="(item, idx) in link.children" :key="`child_${idx}`">
        <MenuItems :link="item" />
      </template>
    </CNavGroup>
  </template>
  <template v-else>
    <template v-if="link.showInMenu && hasPermission(link)">
      <CNavItem
        :href="
          router.resolve({
            name: link.name,
          }).href
        "
        :active="checkLink(link)"
        @click="deleteStorage"
      >
        <CIcon
          v-if="link?.icon"
          customClassName="nav-icon"
          :icon="link?.icon"
        />
        <div>
          <span v-html="$t('message.menu.' + link.name)"></span>&nbsp;<CButton
            color="info"
            shape="rounded-pill"
            v-if="countStat(link.name)"
            >{{ countStat(link.name) }}</CButton
          >
        </div>
      </CNavItem>
    </template>
  </template>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { TENANT_GROUPS } from '@/config' // Adjust path as needed
const router = useRouter()
const store = useStore()
const stats = ref(null)
const statsCount = ref(null)

const role = computed(() => store.getters.getAdminRole)

defineProps({
  link: Object
})

const checkLink = (link) => {
  if (link.children && link.children.length > 0) {
    for (var i = 0; i < link.children.length; i++) {
      if (checkLink(link.children[i]) == true) {
        return true
      }
    }
  } else {
    return link.name == router.currentRoute.value.name
  }
}

const currentLang = computed(() => localStorage.getItem('lang'))

/*
const hasPermission = (link) => {
  let hasRole = role.value.find((item) => {
    return link.meta.requiredRole.includes(item.name)
  })

  if (hasRole && link.meta.requiredLang.includes(currentLang.value)) {
    return true
  }

  return false
}
*/

// Function to check if the user has permission for a link
function hasPermission(link) {
  // Check if the user's role matches the required role for the route
  const role = false
  // Check if the current tenant matches any allowed tenant groups for the route
  const allowedTenantGroups = link.meta.allowedTenant || []
  const isTenantAllowed = allowedTenantGroups.some((group) => {
    const tenants = TENANT_GROUPS[group] || []
    return tenants.includes(store.getters.tenantId)
  })

  // Debugging: Log the result
  console.log(`${link.name} : role=${role ? 'match' : 'no match'}, tenantAllowed=${isTenantAllowed}`)

  // Return true if both role and tenant checks pass
  return isTenantAllowed
}

const countStat = (name) => {
  const parsedArray = JSON.parse(stats.value)

  if (!parsedArray) {
    return null
  }

  return parsedArray[name]
}

const deleteStorage = () => {
  localStorage.removeItem('id')
  localStorage.removeItem('email')
  localStorage.removeItem('name')
  localStorage.removeItem('username')
  localStorage.removeItem('wholesaler_dealer_id')
  localStorage.removeItem('sale_person_id')
  localStorage.removeItem('date_from')
  localStorage.removeItem('date_to')
  localStorage.removeItem('showAddress')
  localStorage.removeItem('showGroup')
  localStorage.removeItem('showPoints')
  localStorage.removeItem('group_id')
  localStorage.removeItem('role')
  localStorage.removeItem('company_name')
  localStorage.removeItem('selectedCategory')
  localStorage.removeItem('selectedSku')
}

onMounted(() => {
  stats.value = sessionStorage.getItem('stats')
  statsCount.value = sessionStorage.getItem('statsCount')
})
</script>
