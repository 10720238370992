import { AppService } from '../services/app.service'
import { UserService } from '../services/user.service'
import { BTrainingService } from '../services/btraining.service'
import { GroupService } from '../services/group.service'
import { SlideService } from '../services/slider.service'
import { TextPageService } from '../services/textPage.service'
import { StatusService } from '../services/status.service'
import { StatService } from '../services/stat.service'
import { NewsFeedService } from '../services/newsfeed.service'
import { WarehouseService } from '../services/warehouse.service'
import { ProductService } from '../services/product.service'
import { ComplaintService } from '../services/complaint.service'
import router from '@/router'
import { HunterService } from '@/services/hunter.service'
import { PriceService } from '@/services/price.service'
import { TrainingService } from '@/services/training.service'
import { BoosterService } from '@/services/booster.service'
import { PromotionService } from '@/services/promotion.service'
import { SearchService } from '@/services/search.service'
import { MenuService } from '@/services/menu.service'
import { MediaService } from '@/services/media.service'
import { WholesalerService } from '@/services/wholesaler.service'
import { AdexService } from '@/services/adex.service'
import { OrderService } from '@/services/order.service'
import { MessageService } from '@/services/message.service'
import { RoleService } from '@/services/role.service'
import { CategoryService } from '@/services/category.service'
import { SupplierService } from '@/services/supplier.service'
import { ProfileService } from '../services/profile.service'
import { PointSettingsService } from '@/services/pointSettings.service'
import { ShopService } from '@/services/shop.service'
import { BSAppService } from '@/services/bsapp.service'
import { VoucherService } from '@/services/voucher.service'
import { MainBoxService } from '@/services/mainbox.service'
import { SurveyService } from '@/services/survey.service'
import TokenService from '@/services/storage.service'
import { tenantConfig } from '@/configs/tenantConfig'
import { AboutService } from '@/services/about.service'
import { ContactService } from '@/services/contactService.service'

const actions = {
  async loadConnections({ commit }) {
    const response = await AdexService.connections()
    commit('SET_CONNECTIONS', response.data.data)
  },
  async searchCheck({ commit }, data) {
    const response = await SearchService.searchCheck(data)
    commit('SET_CHECKS', response.data.data)
  },
  async loadPromoFilters({ commit }) {
    const response = await PromotionService.resultFilters()
    commit('SET_PROMO_FILTERS', response.data)
  },
  async loadTrainingFilters({ commit }) {
    const response = await TrainingService.resultFilters()
    commit('SET_TRAINING_FILTERS', response.data)
  },
  async loadUserBoostersSelect({ commit }, userId) {
    const response = await BoosterService.fetchUserBoosterSelect(userId)
    commit('SET_USER_BOOSTER_SELECT', response.data)
  },

  async createBooster(_, data) {
    const response = await BoosterService.createBooster(data)
    if (response.status === 200) {
      return true
    } else {
      return response
    }
  },

  async loginUser({ commit }, userData) {
    const response = await UserService.login(userData.email, userData.password, userData.token, userData.productId)

    if (response !== undefined) {
      if (!userData.productId) {
        await router.push({ name: 'dashboard' })
      } else {
        await router.push({
          name: 'product',
          params: { id: userData.productId }
        })
      }
    } else if (response.errors !== undefined) {
      commit('SET_REGISTER_ERRORS', response)
    }
  },

  async loadRoles({ commit }) {
    const response = await RoleService.roles()
    commit('SET_ROLES', response.data.data)
  },

  async loadRolesSelect({ commit }) {
    const response = await RoleService.rolesSelect()
    commit('SET_ROLES_SELECT', response.data.data)
  },

  async loadCategories({ commit }) {
    const response = await CategoryService.categories()
    commit('SET_CATEGORIES', response.data.data)
  },

  async loadBSApps({ commit }) {
    const response = await BSAppService.apps()
    commit('SET_APPS', response.data)
  },

  async loadOrders({ commit }, page) {
    try {
      const response = await OrderService.orders(page)

      commit('SET_ORDERS', response.data)
    } catch (err) {
      return err
    }
  },

  async loadMessages({ commit }, data) {
    try {
      const response = await MessageService.getMessages(data)
      commit('SET_MESSAGES', response.data)
    } catch (err) {
      return err
    }
  },

  async loadMessage({ commit }, id) {
    try {
      const response = await MessageService.getMessage(id)
      commit('SET_MESSAGE', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadThreads({ commit }) {
    try {
      const response = await UserService.getThreads()
      commit('SET_THREADS', response.data)
    } catch (err) {
      console.error(err)
    }
  },

  async loadOrder({ commit }, id) {
    try {
      const response = await OrderService.getOrder(id)
      commit('SET_ORDER', response.data.data)
    } catch (err) {
      console.error(err)
    }
  },

  async loadWarehouses({ commit }, data) {
    try {
      const response = await WarehouseService.warehouses(data)

      commit('SET_WAREHOUSES', response.data.data)
      commit('SET_PAGINATION', response.data.meta.links)
      commit('SET_WAREHOUSES_COUNT', response.data.meta.total)
    } catch (err) {
      return err
    }
  },

  async loadVendors({ commit }) {
    const response = await UserService.vendors()
    commit('SET_VENDOR_LIST', response.data.data)
  },

  async loadSuppliers({ commit }) {
    const response = await SupplierService.suppliers()
    commit('SET_SUPPLIERS_LIST', response.data.data)
  },

  async loadIpcFiles({ commit }) {
    const response = await UserService.ipcFiles()
    commit('SET_FILES', response.data)
  },

  async loadUserFiles({ commit }) {
    const response = await UserService.userFiles()
    commit('SET_USER_FILES', response.data)
  },

  async loadProducts({ commit }, data) {
    const response = await ProductService.products(data)
    commit('SET_PRODUCTS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadFilteredProducts({ commit }, data) {
    const response = await ProductService.filterProducts(data)
    if (response.data.data) {
      commit('SET_PRODUCTS', response.data.data)
      commit('SET_PAGINATION', response.data.meta.links)
    } else {
      commit('SET_PRODUCTS', [])
      commit('SET_PAGINATION', [])
    }
  },

  async loadImportedProducts({ commit }, data) {
    const response = await ProductService.importedProducts(data)
    commit('SET_IMPORTED_PRODUCTS', response.data.data)
    commit('SET_IMPORTED_PRODUCTS_TOTAL', response.data.meta.total)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadSurveys({ commit }) {
    const response = await SurveyService.surveys()
    commit('SET_SURVEYS', response.data.data)
  },

  async loadSurveysActivated({ commit }) {
    const response = await SurveyService.surveysActivated()
    commit('SET_SURVEYS_ACTIVATED', response.data.data)
  },

  async importProducts({ commit }, products) {
    commit('SET_IMPORTED_PRODUCTS', [])
    await ProductService.importProducts(products)
  },

  async loadTrainings({ commit }, page) {
    const response = await TrainingService.trainings(page)
    commit('SET_TRAININGS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadBTrainings({ commit }, page) {
    const response = await BTrainingService.trainings(page)
    commit('SET_B_TRAININGS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadHunterDetails({ commit }, page) {
    const response = await HunterService.hunterDetails(page)
    commit('SET_HUNTER_DETAILS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadSliders({ commit }, page) {
    const response = await SlideService.slides(page)
    commit('SET_SLIDERS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadPrices({ commit }, page) {
    const response = await PriceService.prices(page)
    commit('SET_PRICES', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadTextPages({ commit }, page) {
    const response = await TextPageService.textPages(page)
    commit('SET_TEXT_PAGES', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadMedias({ commit }, page) {
    const response = await MediaService.medias(page)
    commit('SET_MEDIAS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadLoginHistory({ commit }, data) {
    const response = await UserService.loadUserLoginHistory(data.id, data.page)
    commit('SET_LOGIN_HISTORY', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadLoginsHistory({ commit }, data) {
    const response = await UserService.loadLoginHistory(data.page)
    commit('SET_LOGIN_HISTORY', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadTrainingsResults({ commit }, data) {
    const response = await StatService.trainingResults(data)
    commit('SET_TRAINING_RESULTS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadBTrainingsResults({ commit }, page) {
    const response = await StatService.bTrainingResults(page)
    commit('SET_B_TRAINING_RESULTS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadExternalTrainingsResults({ commit }, page) {
    const response = await StatService.externalTrainingResults(page)
    commit('SET_EXTERNAL_TRAINING_RESULTS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadInvestmentsResults({ commit }, page) {
    const response = await StatService.investmentResults(page)
    commit('SET_EXTERNAL_TRAINING_RESULTS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadHunterResults({ commit }, page) {
    const response = await StatService.hunterResults(page)
    commit('SET_HUNTER_RESULTS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadUberallResults({ commit }, page) {
    const response = await StatService.uberallResults(page)
    commit('SET_UBERALL_RESULTS', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadProductsSelect({ commit }, withHashId = false) {
    const response = await ProductService.productsSelect(withHashId)
    commit('SET_PRODUCTS_SELECT', response.data.data)
  },

  async loadProductsCategory({ commit }, id) {
    const response = await ProductService.productsCategory(id)
    commit('SET_PRODUCTS', response.data.data)
  },

  async loadRoleById({ commit }, id) {
    const response = await RoleService.role(id)
    commit('SET_ROLE', response.data.data)
  },

  async loadSinglePriceListById({ commit }, id) {
    const response = await PriceService.singlePriceList(id)
    commit('SET_SINGLE_PRICE_LIST', response.data.data)
  },

  async loadHunterDetailById({ commit }, id) {
    const response = await HunterService.hunterDetail(id)
    commit('SET_HUNTER_DETAIL', response.data.data)
  },

  async loadCategoryById({ commit }, id) {
    const response = await CategoryService.category(id)
    commit('SET_CATEGORY', response.data.data)
  },

  async loadAppById({ commit }, id) {
    const response = await BSAppService.app(id)
    commit('SET_APP', response.data)
  },

  async loadVendorById({ commit }, id) {
    const response = await UserService.vendor(id)
    commit('SET_VENDOR', response.data.data)
  },

  async loadSupplierById({ commit }, id) {
    const response = await UserService.supplier(id)
    commit('SET_SUPPLIER', response.data.data)
  },

  async loadUserById({ commit }, id) {
    const response = await UserService.user(id)
    commit('SET_USER_MODEL', response.data.data)
    commit('SET_INITIAL_USER_ROLE', response.data.data.modelRoles)
  },

  async loadAttributeById({ commit }, id) {
    const response = await UserService.attribute(id)
    commit('SET_ATTRIBUTE', response.data.data)
  },

  async loadOptionById({ commit, dispatch }, id) {
    const response = await UserService.option(id)
    commit('SET_OPTION', response.data.data)

    if (response) {
      dispatch('loadLanguages')
    }
  },

  async loadGroupById({ commit }, id) {
    const response = await GroupService.group(id)
    commit('SET_GROUP', response.data.data)
  },

  async loadProductById({ commit }, id) {
    const response = await ProductService.product(id)
    commit('SET_PRODUCT', response.data.data)
  },

  async loadTrainingById({ commit }, id) {
    const response = await TrainingService.training(id)
    commit('SET_TRAINING', response.data.data)
  },

  async loadBTrainingById({ commit }, id) {
    const response = await BTrainingService.training(id)
    commit('SET_B_TRAINING', response.data.data)
  },

  async startIpc({ commit }, filename) {
    const response = await UserService.startIpcImport(filename)
    commit('SET_IMPORT', response.data?.success)
  },

  async startUser({ commit }, form) {
    const response = await UserService.startUserImport(form)
    commit('SET_IMPORT', response.data?.success)
  },

  async loadPermissions({ commit }) {
    try {
      const response = await UserService.permissions()
      commit('SET_PERMISSIONS', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadPermissionsForSelect({ commit }) {
    const response = await UserService.permissionsForSelect()
    commit('SET_PERMISSIONS_SELECT', response.data.data)
  },

  async loadCategoriesForSelect({ commit }) {
    const response = await CategoryService.categoriesForSelect()
    commit('SET_CATEGORIES_SELECT', response.data.data)
  },

  async loadUsers({ commit }, data) {
    let response = null

    if (data?.id) {
      response = await RoleService.rolesUsers(data.id)
    } else {
      response = await UserService.users(data.page, data.status, data.show_points)
    }

    let users = []
    if (response.data.data) {
      users = response.data.data.map((user) => {
        var text_validate = null
        if (user.iban_status) {
          text_validate = ProfileService.getMessageByStatusIban(user.iban_status, user.admin_do_match)
        }
        return {
          id: user.id,
          planId: user.planId,
          name: user.name,
          company: user.company,
          email: user.email,
          iban_number: user.iban_number,
          iban_status: user.iban_status,
          admin_do_match: user.admin_do_match,
          last_login: user.last_login,
          is_active: user.is_active,
          created_at: user.created_at,
          rejected: user.rejected,
          is_online: user.is_online,
          username: user.username,
          text_validate: text_validate,
          points: user.points
        }
      })
      commit('SET_USERS', users)
      commit('SET_PAGINATION', response.data.meta.links)
    }
  },

  async loadUsersList({ commit }, data) {
    let response = null

    response = await UserService.usersList(data.page)
    let users = []
    if (response.data.data) {
      users = response.data.data.map((user) => {
        var text_validate = null
        if (user.iban_status) {
          text_validate = ProfileService.getMessageByStatusIban(user.iban_status, user.admin_do_match)
        }
        return {
          id: user.id,
          planId: user.planId,
          name: user.name,
          company: user.company,
          email: user.email,
          iban_number: user.iban_number,
          iban_status: user.iban_status,
          admin_do_match: user.admin_do_match,
          last_login: user.last_login,
          is_active: user.is_active,
          created_at: user.created_at,
          rejected: user.rejected,
          is_online: user.is_online,
          username: user.username,
          text_validate: text_validate,
          points: user.points
        }
      })
      commit('SET_USERS', users)
      commit('SET_PAGINATION', response.data.meta.links)
    }
  },
  async loadSearchFilter({ commit, state }, data) {
    try {
      if (state.usersPending) {
        return true
      } else {
        commit('SET_USERS_PENDING', true)
        const response = await UserService.filterSearch(data)
        let users = []
        if (response.data.data) {
          users = response.data.data.map((user) => {
            var text_validate = null
            if (user.iban_status) {
              text_validate = ProfileService.getMessageByStatusIban(user.iban_status, user.admin_do_match)
            }
            return {
              id: user.id,
              planId: user.planId,
              name: user.name,
              company: user.company,
              email: user.email,
              iban_number: user.iban_number,
              iban_status: user.iban_status,
              admin_do_match: user.admin_do_match,
              last_login: user.last_login,
              is_active: user.is_active,
              created_at: user.created_at,
              rejected: user.rejected,
              is_online: user.is_online,
              username: user.username,
              text_validate: text_validate,
              points: user.points
            }
          })
          commit('SET_USERS', users)
          commit('SET_PAGINATION', response.data.meta.links)
          commit('SET_USERS_PENDING', false)
        }
      }
    } catch (err) {
      commit('SET_USERS_PENDING', false)
      return err
    }
  },
  async uploadImage({ commit }, data) {
    try {
      commit('SET_IS_LOADING', true)
      const response = await UserService.uploadImage(data.id, data.form)

      if (response) {
        commit('SET_IS_LOADING', false)
        router.push({ name: 'product', params: { id: data.id } })
      }
    } catch (err) {
      return err
    }
  },

  async loadLanguages({ commit }) {
    try {
      const response = await UserService.languages()
      commit('SET_LANGS', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadVendorsForSelect({ commit }) {
    try {
      const response = await UserService.vendorsSelect()
      commit('SET_VENDORS_SELECT', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadSuppliersForSelect({ commit }) {
    try {
      const response = await SupplierService.suppliersSelect()
      commit('SET_SUPPLIERS_SELECT', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadAttributesForSelect({ commit }) {
    try {
      const response = await UserService.attributesSelect()
      commit('SET_ATTRIBUTES_SELECT', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadAttributes({ commit }) {
    try {
      const response = await UserService.attributes()
      commit('SET_ATTRIBUTES', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadOptions({ commit }) {
    try {
      const response = await UserService.options()
      commit('SET_OPTIONS', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadOptionsSelect({ commit }) {
    try {
      const response = await UserService.optionsSelect()
      commit('SET_OPTIONS_SELECT', response.data.data)
    } catch (err) {
      return err
    }
  },

  async me({ commit }) {
    try {
      const adminData = TokenService.getAdminData()
      if (!adminData) {
        const response = await UserService.me()
        commit('SET_USER', response.data.data)
        commit('SET_ADMIN_ROLE', response.data.data.roles)
        TokenService.saveAdminData(JSON.stringify(response.data.data))
      } else {
        const data = JSON.parse(adminData)
        commit('SET_JOB_POSITIONS', data)
        commit('SET_ADMIN_ROLE', data.roles)
      }
    } catch (err) {
      router.push('/')
    }
  },

  async loadFilter({ commit }) {
    try {
      const response = await UserService.filter()
      commit('SET_FILTER', response.data)
    } catch (err) {
      return err
    }
  },

  async adminMe({ commit }) {
    try {
      const response = await UserService.me()
      commit('SET_USER_ADMIN', response.data.data)
    } catch (err) {
      return err
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveCategory({ commit, state }, data) {
    try {
      const response = await CategoryService.saveCategory(
        {
          ...{ active: data.active },
          ...{ parent_id: data.parent_id },
          ...{ name: data.name },
          ...{ image: data.image },
          ...{ link: data.link },
          ...{ desc: data.desc },
          ...{ groups: data.groups }
        },
        data.id
      )

      if (response) {
        router.push({ name: 'categories_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeCategory({ commit }, data) {
    try {
      const response = await CategoryService.storeCategory(data)
      if (response) {
        router.push({ name: 'categories_list' })
      }
    } catch (e) {
      return e
    }
  },

  async storeSurvey({ commit }, data) {
    try {
      const response = await SurveyService.storeSurvey(data)
      if (response) {
        router.push({ name: 'surveys_list' })
      }
    } catch (e) {
      return e
    }
  },

  async searchCustomer({ commit }, search) {
    try {
      const response = await UserService.search(search)

      if (response.status === 200 && response.data.data) {
        commit('SET_CUSTOMER', response.data.data)
      } else {
        commit('SET_CUSTOMER', {})
      }
    } catch (e) {
      return e
    }
  },

  async getFields({ commit }) {
    try {
      const response = await BoosterService.boosterFields()
      if (response) {
        commit('SET_BOOSTER_FIELDS', response.data)
      }
    } catch (e) {
      return e
    }
  },

  async addPoints(_, data) {
    try {
      return await UserService.addPoints(data)
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async removeCategory({ commit }, id) {
    try {
      const response = await CategoryService.removeCategory(id)
      if (response) {
        router.push({ name: 'categories_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async removeApp({ commit }, id) {
    try {
      return await AppService.removeApp(id)
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async changeStateApp({ commit }, id) {
    try {
      return await AppService.changeStateApp(id)
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeVendor({ commit }, data) {
    try {
      const response = await UserService.storeVendor(data)
      if (response) {
        router.push({ name: 'vendors_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteVendor({ commit }, id) {
    try {
      const response = await UserService.deleteVendor(id)
      if (response) {
        router.push({ name: 'vendors_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeSupplier({ commit }, data) {
    try {
      const response = await SupplierService.storeSupplier(data)
      if (response) {
        router.push({ name: 'suppliers_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteSupplier({ commit }, id) {
    try {
      const response = await SupplierService.deleteSupplier(id)
      if (response) {
        router.push({ name: 'suppliers_list' })
      }
    } catch (e) {
      return e
    }
  },

  async removeSurvey({ commit }, id) {
    try {
      const response = await SurveyService.removeSurvey(id)
      if (response) {
        router.push({ name: 'surveys_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveVendor({ commit }, data) {
    try {
      const response = await UserService.saveVendor(data, data.id)
      if (response) {
        router.push({ name: 'vendors_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveSupplier({ commit }, data) {
    try {
      const response = await UserService.saveSupplier(data, data.id)
      if (response) {
        router.push({ name: 'suppliers_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeMessage({ commit }, data) {
    try {
      const response = await MessageService.storeMessage(data)
      if (response) {
        router.push({ name: 'messages_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveMessage({ commit }, data) {
    try {
      const response = await MessageService.saveMessage(data, data.id)
      if (response) {
        router.push({ name: 'messages_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteMessage({ commit }, id) {
    try {
      const response = await MessageService.deleteMessage(id)
      if (response) {
        router.push({ name: 'messages_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async createUpdateThreads({ commit }, data) {
    try {
      const response = await UserService.createUpdateThreads(data)
      if (response) {
        router.push({ name: 'threads' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveUser({ commit, state }, data) {
    try {
      const response = await UserService.saveUser(data, data.id)
      if (response) {
        return response
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeOption({ commit }, data) {
    try {
      const response = await UserService.storeOption(data)

      if (response) {
        router.push({ name: 'options_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeGroup({ commit }, data) {
    try {
      const response = await GroupService.storeGroup(data)

      if (response) {
        router.push({ name: 'groups_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveOption({ commit }, data) {
    try {
      const response = await UserService.saveOption(data, data.id)

      if (response) {
        router.push({ name: 'options_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveGroup({ commit }, data) {
    try {
      const response = await GroupService.saveGroup(data, data.id)

      if (response) {
        router.push({ name: 'groups_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveAttribute({ commit, state }, data) {
    try {
      const response = await UserService.saveAttribute({ ...data, ...{ options: state.optionsModel } }, data.id)

      if (response) {
        router.push({ name: 'attributes_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async storeAttribute({ commit, state }, data) {
    try {
      const response = await UserService.storeAttribute({
        ...data,
        ...{ options: state.optionsModel }
      })

      if (response) {
        router.push({ name: 'attributes_list' })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveProduct({ commit, state, dispatch }, data) {
    try {
      const response = await ProductService.saveProduct(
        {
          ...data
        },
        data.id
      )

      if (response) {
        dispatch('loadProductById', data.id)
        dispatch('loadWarehouses', {
          page: 1,
          productId: data.id
        })
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async newProduct({ commit, state }, data) {
    try {
      const response = await ProductService.newProduct({
        ...data,
        ...{ categories: state.categoriesModel }
      })

      return response
    } catch (e) {
      return e
    }
  },

  async newTraining(_, data) {
    try {
      const response = await TrainingService.newTraining({
        ...data
      })

      if (response.status === 200) {
        router.push({
          name: 'training',
          params: { id: response.data.training_id }
        })
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async newWarehouse(_, data) {
    try {
      const response = await WarehouseService.newStore({
        ...data
      })

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async newNewsFeed(_, data) {
    try {
      const response = await NewsFeedService.newNewsFeed({
        ...data
      })

      if (response.status === 200) {
        router.push({
          name: 'news_feed_edit',
          params: { id: response.data.news_feed_id }
        })
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async loadNewsFeeds({ commit }, page) {
    try {
      const response = await NewsFeedService.newsFeeds(page)
      commit('SET_NEWS_FEEDS', response.data.data)
      commit('SET_PAGINATION', response.data.meta.links)
    } catch (err) {
      return err
    }
  },

  async loadInactiveUsers({ commit }, data) {
    try {
      const response = await UserService.fetchInactiveUsers(data)
      let dealers = []
      if (response.data.data) {
        dealers = response.data.data.map((user) => {
          var text_validate = null
          if (user.iban_status) {
            text_validate = ProfileService.getMessageByStatusIban(user.iban_status, user.admin_do_match)
          }
          return {
            id: user.id,
            planId: user.planId,
            name: user.name,
            email: user.email,
            iban_number: user.iban_number,
            iban_status: user.iban_status,
            admin_do_match: user.admin_do_match,
            created_at: user.created_at,
            dealer_id: user.dealer_id,
            text_validate: text_validate,
            company: user.company,
            customer_number: user.customer_number,
            request_from: user.request_from,
            points: user.points
          }
        })
        commit('SET_CS_NUMBERS_TO_APPROVE', dealers)
        commit('SET_INACTIVE_USERS', dealers)
        commit('SET_PAGINATION', response.data.meta.links)
      }
    } catch (err) {
      return err
    }
  },

  async loadCsNumbersToApprove({ commit }, data) {
    try {
      const response = await UserService.fetchCustomerNumbersToApprove(data)
      let dealers = []
      if (response.data.data) {
        dealers = response.data.data.map((user) => {
          return {
            id: user.id,
            planId: user.planId,
            name: user.name,
            email: user.email,
            iban_number: user.iban_number,
            iban_status: user.iban_status,
            admin_do_match: user.admin_do_match,
            created_at: user.created_at,
            dealer_id: user.dealer_id,
            points: user.points
          }
        })
        commit('SET_CS_NUMBERS_TO_APPROVE', dealers)
        commit('SET_PAGINATION', response.data.meta.links)
      }
    } catch (err) {
      return err
    }
  },

  async doMatchByUserID({ commit }, id) {
    const response = await ProfileService.doMatch(id)
    var text_validate = null
    console.log('response = ')
    if (response.data.iban_status) {
      text_validate = ProfileService.getMessageByStatusIban(response.data.iban_status, 1)
    }
    var status_match = {
      statusMatch: response.data.iban_status.statusMatch,
      message: text_validate,
      showMessageIbanStatus: true
    }
    commit('SET_MESSAGE_STATUS_MATCH', status_match)
  },
  setIbanStatus({ commit }, data) {
    var status_match = {
      statusMatch: data.statusMatch,
      message: data.message,
      showMessageIbanStatus: data.showMessageIbanStatus
    }
    commit('SET_MESSAGE_STATUS_MATCH', status_match)
  },

  async loadPriceList({ commit }, page) {
    try {
      const response = await PriceService.priceList(page)
      commit('SET_PRICE_LIST', response.data.data)
      commit('SET_PAGINATION', response.data.meta.links)
    } catch (err) {
      return err
    }
  },

  async loadDealers({ commit }) {
    const response = await UserService.loadDealersUsers()
    let dealers = []

    if (response) {
      dealers = response.map((user) => {
        return {
          value: user.id,
          label: `${user.name}, ${user.email}`
        }
      })

      commit('SET_DEALERS', dealers)
    }
  },

  async loadProductDealers({ commit }) {
    const response = await ProductService.loadProductDealersUsers()
    let dealers = []

    if (response) {
      dealers = response.data.map((user) => {
        return {
          value: user.id,
          label: user.name
        }
      })

      commit('SET_DEALERS', dealers)
    }
  },

  async newQuestion(_, data) {
    try {
      const response = await UserService.newQuestion({
        ...data
      })

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async loadQuestions({ commit }, trainingId) {
    try {
      const response = await UserService.questions(trainingId)
      commit('SET_QUESTIONS', response.data)
    } catch (err) {
      return err
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveRole({ commit, state }, data) {
    try {
      const response = await UserService.saveRole(data?.id, {
        data: {
          name: data.name
        },
        permissions: state.permissionsModel
      })
      if (response) {
        router.push({ name: 'roles_list' })
      }
    } catch (err) {
      return err
    }
  },

  async deleteQuestion(_, id) {
    try {
      const response = await UserService.deleteQuestionById(id)

      if (response.status === 200) {
        return true
      }
    } catch (err) {
      return err
    }
  },

  // eslint-disable-next-line no-unused-vars
  async updateQuestion(_, data) {
    try {
      const response = await UserService.updateQuestion(data.id, data)

      if (response.status === 200) {
        return true
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async updateQuantity(_, data) {
    try {
      const response = await WarehouseService.updateWarehouse(data.id, data)

      if (response.status === 200) {
        return true
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async updateNewsFeed(_, data) {
    try {
      const response = await NewsFeedService.updateNewsFeed(data.id, data)

      if (response.status === 200) {
        return true
      }
    } catch (e) {
      return e
    }
  },

  async loadRequestStats({ commit }) {
    try {
      const response = await StatService.requestCounts()
      commit('SET_REQUEST_STATS', response.data)
      const jsonArray = JSON.stringify(response.data)

      let statsCount = 0

      for (let c in response.data) {
        if (c !== 'orders' && c !== 'inactive_users' && c !== 'customer_numbers_to_approve') {
          statsCount += response.data[c]
        }
      }

      sessionStorage.setItem('stats', jsonArray)
      sessionStorage.setItem('statsCount', statsCount)
    } catch (err) {
      return err
    }
  },

  async orderQuestion(_, data) {
    try {
      const response = await UserService.reorderQuestion({
        ...data
      })

      if (response.status === 200) {
        return true
      }
    } catch (e) {
      return e
    }
  },

  async orderSlider(_, data) {
    try {
      const response = await SlideService.reorderSlider({
        ...data
      })

      if (response.status === 200) {
        return true
      }
    } catch (e) {
      return e
    }
  },

  async orderNews(_, data) {
    try {
      const response = await NewsFeedService.reorderNews({
        ...data
      })

      if (response.status === 200) {
        return true
      }
    } catch (e) {
      return e
    }
  },

  async orderCategories(_, data) {
    try {
      const response = await CategoryService.reorderCategories({
        ...data
      })

      if (response.status === 200) {
        return true
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async updateTraining(_, data) {
    try {
      const response = await TrainingService.updateTraining(data, data.id)

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async updateSlide(_, data) {
    try {
      const response = await SlideService.updateSlide(data, data.id)

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async updatePrice(_, data) {
    try {
      const response = await PriceService.updatePrice(data, data.get('id'))

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async newBTraining(_, data) {
    try {
      const response = await BTrainingService.newTraining({
        ...data
      })

      if (response.status === 200) {
        router.push({
          name: 'btraining',
          params: { id: response.data.btraining_id }
        })
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async updateBTraining(_, data) {
    try {
      const response = await BTrainingService.updateTraining(data, data.id)

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async updateSurvey(_, data) {
    try {
      const response = await SurveyService.updateSurvey(data, data.id)

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async setActivatedSurvey(_, id) {
    try {
      const response = await SurveyService.setActivatedSurvey(id)

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async deleteBTrainingDetail(_, id) {
    try {
      const response = await BTrainingService.deleteBTrainingDetailById(id)

      if (response.status === 200) {
        return true
      }
    } catch (err) {
      return err
    }
  },

  async loadBTrainingTypes({ commit }) {
    const response = await BTrainingService.bTrainingTypes()
    commit('SET_B_TRAINING_TYPES', response.data.data)
  },

  async loadPriceCategories({ commit }) {
    const response = await PriceService.priceCategories()
    commit('SET_PRICE_CATEGORIES', response.data.data)
  },

  async loadBrochureCategories({ commit }) {
    const response = await PriceService.brochureCategories()
    commit('SET_BROCHURE_CATEGORIES', response.data.data)
  },

  async loadBrochures({ commit }, page) {
    const response = await PriceService.brochures(page)
    commit('SET_BROCHURES', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadBrochureById({ commit }, id) {
    const response = await PriceService.loadBrochureById(id)
    commit('SET_BROCHURE', response.data.data)
  },

  async loadBrochureFiles({ commit }, id) {
    const response = await PriceService.brochureFiles(id)
    console.log(response.data)
    commit('SET_BROCHURE_FILES', response.data)
  },

  async loadBTrainingCosts({ commit }) {
    const response = await BTrainingService.bTrainingCosts()
    commit('SET_B_TRAINING_COSTS', response.data.data)
  },

  async newBTrainingDetails(_, data) {
    try {
      const response = await BTrainingService.newBTrainingDetail(data)

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async loadBTrainingDetails({ commit }, trainingId) {
    const response = await BTrainingService.bTrainingDetails(trainingId)
    commit('SET_B_TRAINING_DETAILS', response.data.data)
  },

  // eslint-disable-next-line no-unused-vars
  async loadParentAttributes({ commit }, id) {
    try {
      commit('SET_PARENT_ATTRIBUTE_PENDING', true)
      commit('SET_PARENT_ATTRIBUTES', [])
      const response = await GroupService.parentAttributes(id)
      commit('SET_PARENT_ATTRIBUTES', response.data)
      commit('SET_PARENT_ATTRIBUTE_PENDING', false)
    } catch (err) {
      return err
    }
  },

  // eslint-disable-next-line no-unused-vars
  async loadGroups({ commit }) {
    try {
      const response = await GroupService.groups()
      commit('SET_GROUPS', response.data.data)
    } catch (err) {
      return err
    }
  },

  // eslint-disable-next-line no-unused-vars
  async loadParentGroups({ commit }) {
    try {
      const response = await GroupService.parentGroups()
      commit('SET_PARENT_GROUPS', response.data.data)
    } catch (err) {
      return err
    }
  },

  // eslint-disable-next-line no-unused-vars
  async newSlider({ state }, data) {
    try {
      const response = await SlideService.newSlide({
        ...data
      })

      if (response.status === 200) {
        router.push({ name: 'sliders_list' })
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  // eslint-disable-next-line no-unused-vars
  async newTextPage(_, data) {
    try {
      const response = await TextPageService.newTextPage(data)

      if (response.status === 200) {
        router.push({ name: 'textpages_list' })
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async loadSlideById({ commit }, id) {
    const response = await SlideService.slide(id)
    commit('SET_SLIDE', response.data.data)
  },

  async loadPriceById({ commit }, id) {
    const response = await PriceService.price(id)
    commit('SET_PRICE', response.data.data)
  },

  async loadNewsFeedById({ commit }, id) {
    const response = await NewsFeedService.newsFeed(id)
    commit('SET_NEWS_FEED', response.data.data)
  },

  async loadTextPageById({ commit }, id) {
    const response = await TextPageService.textPage(id)
    commit('SET_TEXT_PAGE', response.data.data)
  },

  async loadMediaPageById({ commit }, id) {
    const response = await MediaService.mediaPage(id)
    commit('SET_MEDIA_PAGE', response.data.data)
  },

  async loadMediaPageFiles({ commit }, id) {
    const response = await MediaService.mediaPageFiles(id)
    commit('SET_MEDIA_PAGE_FILES', response.data)
  },

  async loadSurveyById({ commit }, id) {
    const response = await SurveyService.survey(id)
    commit('SET_SURVEY', response.data.data)
    commit('SET_SURVEY_GROUPS', response.data.data.groups)
  },

  updateValueSurveyGroups({ commit }, data) {
    commit('SET_SURVEY_GROUPS', data)
  },

  // eslint-disable-next-line no-unused-vars
  async updateTextPage(_, data) {
    try {
      const response = await TextPageService.updateTextPage(data, data.id)

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },

  async deleteSlide(_, id) {
    try {
      const response = await SlideService.deleteSlideById(id)

      if (response.status === 200) {
        return true
      }
    } catch (err) {
      return err
    }
  },

  async deletePrice(_, id) {
    try {
      const response = await PriceService.deletePriceById(id)

      if (response.status === 200) {
        return true
      }
    } catch (err) {
      return err
    }
  },

  async deleteTextPage(_, id) {
    try {
      const response = await TextPageService.deleteTextPageById(id)

      if (response.status === 200) {
        return true
      }
    } catch (err) {
      return err
    }
  },

  async deleteNewsFeed(_, id) {
    try {
      const response = await NewsFeedService.deleteNewsFeedById(id)

      if (response.status === 200) {
        return true
      }
    } catch (err) {
      return err
    }
  },

  async deleteNewsFeedSlide(_, id) {
    try {
      const response = await NewsFeedService.deleteNewsFeedSlide(id)

      if (response.status === 200) {
        return true
      }
    } catch (err) {
      return err
    }
  },

  async newOrderStatus(_, data) {
    try {
      const response = await StatusService.newStatus({
        ...data
      })

      if (response.status === 200) {
        return true
      } else {
        return response
      }
    } catch (e) {
      return e
    }
  },
  // eslint-disable-next-line no-unused-vars
  async loadSetting({ commit }, slug) {
    try {
      const response = await UserService.getSettings(slug)
      if (response.status == 200) {
        commit('SET_SETTING', response.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async setSetting({ commit }, data) {
    try {
      await UserService.setSetting(data)
    } catch (e) {
      console.error(e)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async saveFeaturedProducts({ commit }, data) {
    try {
      await UserService.saveFeaturedProducts(data)
    } catch (e) {
      console.error(e)
    }
  },

  async loadFeaturedProducts({ commit }) {
    try {
      const response = await UserService.loadFeaturedProducts()
      if (response.status == 200) {
        commit('SET_FEATURED_PRODUCTS', response.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  // eslint-disable-next-line no-unused-vars
  addToast({ commit }, data) {
    commit('SET_TOAST', data)
  },

  // eslint-disable-next-line no-unused-vars
  addPending({ commit }, data) {
    commit('SET_PENDING', data)
  },

  async loadThreadsBySlug({ commit }, slug) {
    try {
      const response = await UserService.loadThreadsBySlug(slug)
      if (response.status == 200) {
        commit('SET_THREADS_BY_SLUG', {
          data: response.data,
          slug: slug
        })
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadThreadsUsers({ commit }) {
    try {
      const response = await UserService.loadThreadsUsers()
      if (response.status == 200) {
        commit('SET_THREADS_USERS', response.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async loadStats({ commit }) {
    try {
      const response = await AppService.getStats()
      if (response.status == 200) {
        commit('SET_STATS', response.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadSalesPromotions({ commit }, data) {
    try {
      const response = await PromotionService.getSalesPromotions(data)
      if (response.status == 200) {
        commit('SET_SALES_PROMOTIONS', response.data.data)
        commit('SET_PAGINATION', response.data.meta.links)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadBoosterPreview({ commit }) {
    try {
      const response = await BoosterService.boostersPreview()
      if (response.status == 200) {
        commit('SET_BOOSTERS_PREVIEW', response.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadBoosters({ commit }, $page = 1) {
    try {
      const response = await BoosterService.boosters($page)
      if (response.status == 200) {
        commit('SET_BOOSTERS_LIST', response.data.data)
        commit('SET_PAGINATION', response.data.meta.links)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadComplaints({ commit }, $page = 1) {
    try {
      const response = await ComplaintService.getComplaints($page)
      if (response.status == 200) {
        commit('SET_COMPLAINTS', response.data.data)
        commit('SET_PAGINATION', response.data.meta.links)
      }
    } catch (e) {
      console.error(e)
    }
  },

  setMenuVisible({ commit }, data) {
    commit('SET_MENU_VISIBLE', data)
  },

  // eslint-disable-next-line no-unused-vars
  async loadWholesalers({ commit }) {
    try {
      const response = await UserService.loadWholesalersUsers()
      commit('SET_WHOLESALERS', response.allWholesalers)
    } catch (e) {
      console.error(e)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async loadUserOrders({ commit }, data) {
    try {
      const response = await UserService.loadUserOrders(data.id, data.page)
      commit('SET_USER_ORDERS', response.data.data)
      commit('SET_PAGINATION', response.data.meta.links)
    } catch (e) {
      console.error(e)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async loadBankStatement({ commit }, data) {
    try {
      const response = await UserService.loadBankStatement(data.id, data.page)
      commit('SET_BANK_STATEMENT', response.data.data)
    } catch (e) {
      console.error(e)
    }
  },

  // eslint-disable-next-line no-unused-vars
  async loadAllBankStatement({ commit }, data) {
    try {
      const response = await UserService.loadAllBankStatement(data.page)
      commit('SET_BANK_STATEMENT', response.data.data)
    } catch (e) {
      console.error(e)
    }
  },

  async loadDeletedUsers({ commit }, page) {
    try {
      const response = await UserService.loadDeletedUsers(page)
      commit('SET_DELETED_USERS', response.data.data)
      commit('SET_PAGINATION', response.data.meta.links)
    } catch (e) {
      console.error(e)
    }
  },

  async loadStaffs({ commit }) {
    try {
      const response = await UserService.staffs()
      commit('SET_STAFFS', response.data)
    } catch (err) {
      return err
    }
  },

  async loadComplaint({ commit }, id) {
    try {
      const response = await ComplaintService.getComplaint(id)
      if (response.status == 200) {
        commit('SET_COMPLAINT', response.data.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadCertificateTexts({ commit }, id) {
    try {
      const response = await TrainingService.certificateTexts(id)

      if (response.status == 200) {
        commit('SET_CERTIFICATE_TEXTS', response.data.data)
      }
    } catch (e) {
      console.error(e)
    }
  },
  async loadActivities({ commit }, { page = 1, userHashId, search }) {
    try {
      const response = await UserService.getActivities(userHashId, page, search)
      if (response.status == 200) {
        commit('SET_ACTIVITIES', response.data.data)
        commit('SET_PAGINATION_ACTIVITY', response.data.meta)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadGlobalActivities({ commit }, { page = 1, search }) {
    try {
      const response = await UserService.getGlobalActivities(page, search)
      if (response.status == 200) {
        commit('SET_ACTIVITIES_GLOBAL', response.data.data)
        commit('SET_PAGINATION_ACTIVITY_GLOBAL', response.data.meta)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async filterActivities({ commit }, { page = 1, ev, type, users, customer_numbers }) {
    try {
      const response = await UserService.filterActivity(page, ev, type, users, customer_numbers)
      if (response.status == 200) {
        commit('SET_ACTIVITIES_GLOBAL', response.data.data)
        commit('SET_PAGINATION_ACTIVITY_GLOBAL', response.data.meta)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadActivityFilters({ commit }) {
    try {
      const response = await UserService.getActivityFilters()
      if (response.status == 200) {
        commit('SET_ACTIVITY_FILTERS', response.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadInactiveBoosters({ commit }, userId) {
    const response = await BoosterService.inactiveUserBoosters(userId)
    commit('SET_INACTIVE_BOOSTERS', response.data.data)
  },

  async loadOrderItemStats({ commit }) {
    const response = await StatService.getIsNewOrderItems()
    commit('SET_ORDER_STATS', response.data)
  },

  async searchUsers({ commit }, data) {
    try {
      const response = await SearchService.search(data)
      commit('SET_USERS', response.data.data)
      commit('SET_PAGINATION', null)
    } catch (err) {
      return err
    }
  },

  async filterUsers({ commit }, data) {
    try {
      const response = await SearchService.filter(data)
      commit('SET_USER_FILTERS', response.data.data)
    } catch (err) {
      return err
    }
  },

  async filterMenu({ commit }, data) {
    try {
      const response = await MenuService.filter(data)
      commit('SET_MENU_FILTERS', response.data)
    } catch (err) {
      return err
    }
  },

  async loadMediaCategory({ commit }) {
    const response = await MediaService.mediaCategories()
    commit('SET_MEDIA_CATEGORIES', response.data.data)
  },

  async loadWholesalerManagement({ commit }) {
    try {
      const response = await WholesalerService.wholesalerManagements()
      if (response.status == 200) {
        commit('SET_WHOLESALER_MANAGEMENT', response.data.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadDefaultBanners({ commit }) {
    try {
      const response = await MediaService.defaultBanners()
      if (response.status == 200) {
        commit('SET_DEFAULT_BANNERS', response.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadPointSettings({ commit }) {
    try {
      const response = await PointSettingsService.loadPointSettings()
      commit('SET_POINT_SETTINGS', response.data.data)
    } catch (err) {
      return err
    }
  },

  async loadShopLimits({ commit }) {
    try {
      const response = await ShopService.loadShopLimitList()
      commit('SET_SHOP_LIMITS', response)
    } catch (err) {
      return err
    }
  },

  async filterMediaFiles({ commit }, data) {
    try {
      const response = await MediaService.fetchFilteredMedia(data)

      if (response.status == 200) {
        commit('SET_MEDIA_FILES', response.data.data)
        commit('SET_PAGINATION', response.data.links)
        commit('SET_TOTAL_MEDIA_FILES', response.data.total)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async filterBrochureFiles({ commit }, data) {
    try {
      const response = await PriceService.fetchFilteredBrochure(data)

      if (response.status == 200) {
        commit('SET_BROCHURE_FILES', response.data.data)
        commit('SET_PAGINATION', response.data.meta.links)
        commit('SET_TOTAL_BROCHURE_FILES', response.data.meta.total)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadVoucherSelect({ commit }) {
    try {
      const response = await VoucherService.select()

      if (response.status == 200) {
        commit('SET_VOUCHER_SELECT', response.data.data)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async loadVouchers({ commit }, data) {
    try {
      const response = await VoucherService.vouchers(data)

      if (response.status == 200) {
        commit('SET_VOUCHERS', response.data.data)
        commit('SET_PAGINATION', response.data.meta.links)
      }
    } catch (e) {
      console.error(e)
    }
  },

  setUserFilters({ commit }, data) {
    commit('SET_FILTERS_USER', data)
  },

  async loadMainBoxes({ commit }, data) {
    const response = await MainBoxService.getMainBoxes(data)
    commit('SET_MAIN_BOXES', response.data.data)
    commit('SET_PAGINATION', response.data.meta.links)
  },

  async loadMainBox({ commit }, id) {
    const response = await MainBoxService.getMainBox(id)
    commit('SET_MAIN_BOX', response.data.data)
  },

  async loadUserApps({ commit }, id) {
    const userResponse = await UserService.user(id)
    const response = await UserService.userApps(userResponse.data.data.plainId)
    commit('SET_USER_APPS', response.data)
  },

  addInitialParentAttributes({ commit }, data) {
    commit('SET_INITIAL_PARENT_ATTRIBUTES', data)
  },

  async loadTenantConfig({ commit }, countryCode) {
    const countryConfig = tenantConfig.tenants[countryCode]
    if (countryConfig) {
      commit('SET_IS_LITE_VERSION', countryConfig.liteVersion)
      commit('SET_AVAILABLE_MODULES', countryConfig.modules)
      commit('SET_TENANCY_ENABLED', true)
      commit('SET_TENANT_ID', countryCode)
    } else {
      commit('SET_TENANCY_ENABLED', false)
    }
  },

  async loadAboutList({ commit }, page = 1) {
    const response = await AboutService.list(page)

    if (response.status == 200) {
      const sorted = response.data.data.sort((a, b) => a.order - b.order)
      commit('SET_ABOUT_LIST', sorted)
      commit('SET_PAGINATION', response.data.meta.links)
    }
  },

  async loadAbout({ commit }, id) {
    const response = await AboutService.show(id)

    commit('SET_ABOUT', response.data)
  },
  async loadContactServiceList({ commit }, page = 1) {
    const response = await ContactService.list(page)

    commit('SET_CONTACT_SERVICES', response.data.data)
    commit('SET_PAGINATION', response.data.links)
  },
  async loadContactService({ commit }, id) {
    const response = await ContactService.show(id)

    commit('SET_CONTACT_SERVICE', response.data)
  }
}

export { actions }
