<template>
  <div>
    <CSidebar
      :visible="visible"
      :class="{ show: visible == true, hide: visible == false }"
      @visible-change="store.dispatch('setMenuVisible', $event)"
      position="fixed"
      class="sidebar--width"
    >
      <CSidebarBrand>
        <CCloseButton
          white
          class="close mx-2"
          @click="store.dispatch('setMenuVisible', false)"
        />
      </CSidebarBrand>
      <CSidebarNav>
        <template v-for="(link, id) in links" :key="`parent_${id}`">
          <MenuItems :link="link" v-if="hasPermission(link)"></MenuItems>
        </template>
      </CSidebarNav>
    </CSidebar>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view name="content" />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script setup>
import { CContainer, CSidebar } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import { useStore } from 'vuex'
import { ref, computed, watch, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import MenuItems from '@/components/MenuItems.vue'
import { TENANT_GROUPS } from '@/config' // Adjust path as needed

const store = useStore()
const router = useRouter()

// Define reactive references
const user = computed(() => store.getters.getAdminMe)
const links = ref(router.getRoutes().filter((item) => item.meta.isParent === true))
const isLoadingUser = ref(true)

// Dispatch Vuex actions and set loading state
store.dispatch('me').then(() => {
  isLoadingUser.value = false
})

// Function to check if the user has permission for a link
function hasPermission(link) {
  if (isLoadingUser.value) {
    // Optionally handle the loading state, e.g., return false or a loading indicator
    return false
  }

  const userRoles = user.value?.roles || []

  // Debugging: Log the roles and permissions
  //console.log('User roles:', userRoles)
  //console.log('Required roles for link:', link.meta.requiredRole)
  //console.log('Current tenant:', currentTenant)
  //console.log('Allowed tenant groups for link:', link.meta.allowedTenant)

  // Check if the user's role matches the required role for the route
  const role = userRoles.find((item) => link.meta.requiredRole.includes(item.name))

  // Check if the current tenant matches any allowed tenant groups for the route
  const allowedTenantGroups = link.meta.allowedTenant || []
  const isTenantAllowed = allowedTenantGroups.some((group) => {
    const tenants = TENANT_GROUPS[group] || []
    return tenants.includes(store.getters.tenantId)
  })

  // Debugging: Log the result
  console.log(`${link.name} : role=${role ? 'match' : 'no match'}, tenantAllowed=${isTenantAllowed}`)

  // Return true if both role and tenant checks pass
  return isTenantAllowed
}

// Define a computed property for the sidebar visibility
const visible = computed(() => store.getters.getMenuVisible)

// Function to handle sidebar visibility based on window width
function showSidebar() {
  const width = window.innerWidth
  store.dispatch('setMenuVisible', width > 800)
}

// Add event listener for window resize
watch(() => window.innerWidth, showSidebar)
window.addEventListener('resize', showSidebar)

// Cleanup event listener when component is unmounted
onUnmounted(() => {
  window.removeEventListener('resize', showSidebar)
})
</script>


<style lang="scss" scoped>
.close {
  display: none;
  @media screen and (max-width: 800px) {
    display: flex;
  }
}
:deep(.sidebar-brand) {
  justify-content: end;
}
</style>
