import users from "@/router/users";
import products from "@/router/products";
import commands from "@/router/commands";
import trainings from "@/router/trainings";
import management from "@/router/management";
import messages from "@/router/messages";
import requests from "@/router/requests";
import points from "@/router/points";
import surveys from "@/router/surveys";
import logs from "@/router/logs";
import apps from "@/router/apps";

const main = [
  {
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      content: () =>
        import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue')
    },
    meta: {
      allowedTenant: ['fullVersion', 'liteVersion']
    }
  },
  {
    path: '/dashboard-matrix',
    name: 'dashboard',
    redirect: '/dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      content: () =>
        import(/* webpackChunkName: "dashboard" */ '@/views/DashboardMatrix.vue')
    },
    meta: {
      allowedTenant: ['fullVersion', 'liteVersion']
    }
  },
  {
    path: '/users-roles',
    name: 'users-roles',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      isParent: true,
      allowedTenant: ['fullVersion', 'liteVersion']
    },
    children: [...users],
    redirect: '/users-roles/roles'
  },
  {
    path: '/products-categories',
    name: 'products-categories',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'en', 'ch', 'nl'],
      isParent: true,
      allowedTenant: ['fullVersion']
    },
    children: [...products],
    redirect: '/products-categories/categories'
  },
  {
    path: '/commands',
    name: 'commands',
    meta: {
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      isParent: true,
      allowedTenant: ['fullVersion']
    },
    children: [...commands],
    redirect: '/commands/ipc'
  },
  {
    path: '/trainings-schulungen',
    name: 'trainings-schulungen',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      isParent: true,
      allowedTenant: ['trainingGroup']
    },
    children: [...trainings],
    redirect: '/trainings-schulungen/trainings'
  },
  {
    path: '/management',
    name: 'management',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      isParent: true,
      allowedTenant: ['fullVersion', 'liteVersion']
    },
    children: [...management],
    redirect: '/management/textpages'
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      isParent: true,
      requiredRole: ['admin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      allowedTenant: ['fullVersion', 'liteVersion']
    },
    children: [...messages],
    redirect: '/settings/messages'
  },
  {
    path: '/requests',
    name: 'requests',
    meta: {
      requiredRole: ['admin', 'middleAdmin', 'lowAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['requestsGroup', 'uberAll'],
      isParent: true
    },
    children: [...requests],
    redirect: '/requests/product-trainings-request'
  },
  {
    path: '/points',
    name: 'points',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['de', 'ch'],
      allowedTenant: ['pointsGroup'],
      isParent: true,
    },
    children: [...points],
    redirect: '/points/cheques'
  },
  {
    path: '/hello-customer-survey',
    name: 'hello-customer-survey',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['nl', 'en'],
      allowedTenant: ['surveyGroup'],
      isParent: true
    },
    children: [...surveys],
    redirect: '/hello-customer-survey'
  },
  {
    path: '/apps',
    name: 'apps',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      isParent: true,
      allowedTenant: ['liteVersion']
    },
    children: [...apps]
  },
  {
    path: '/logs',
    name: 'logs',
    meta: {
      requiredRole: ['admin', 'middleAdmin'],
      requiredLang: ['de', 'nl', 'en', 'ch'],
      isParent: true,
      allowedTenant: ['fullVersion', 'liteVersion']
    },
    children: [...logs],
    redirect: '/logs/activities'
  },
]

export default main
