const mutations = {
  SET_CONNECTIONS(state, payload) {
    state.connections = payload
  },
  SET_USER_FILTERS(state, payload) {
    state.userFilters = payload
  },
  SET_CHECKS(state, payload) {
    state.checks = payload
  },
  SET_ORDER_STATS(state, payload) {
    state.orderStats = payload
  },
  SET_CUSTOMER(state, payload) {
    state.customer = payload
  },
  SET_PENDING(state, payload) {
    state.isPending = payload
  },
  SET_REGISTER_ERRORS(state, payload) {
    state.registerError = payload
  },
  toggleSidebar(state) {
    state.sidebarVisible = !state.sidebarVisible
  },
  toggleUnfoldable(state) {
    state.sidebarUnfoldable = !state.sidebarUnfoldable
  },
  updateSidebarVisible(state, payload) {
    state.sidebarVisible = payload.value
  },

  SET_ROLES(state, payload) {
    state.roles = payload
  },

  SET_ATTRIBUTE(state, payload) {
    state.attribute = payload
  },

  SET_BOOSTER_FIELDS(state, payload) {
    state.boosterFields = payload
  },

  SET_ROLE(state, payload) {
    state.role = payload
  },

  SET_FILTER(state, payload) {
    state.filter = payload
  },

  SET_FILES(state, payload) {
    state.files = payload
  },

  SET_USER_FILES(state, payload) {
    state.userFiles = payload
  },

  SET_OPTIONS(state, payload) {
    state.options = payload
  },

  SET_USER(state, payload) {
    state.user = payload.data
  },

  SET_USER_MODEL(state, payload) {
    state.userModel = payload
  },

  SET_LANGS(state, payload) {
    state.langs = payload
  },

  SET_ATTRIBUTES(state, payload) {
    state.attributes = payload
  },

  SET_VENDORS(state, payload) {
    state.vendors = payload
  },

  SET_VENDORS_SELECT(state, payload) {
    state.vendorsSelect = payload
  },

  SET_SUPPLIERS(state, payload) {
    state.suppliers = payload
  },

  SET_SUPPLIERS_SELECT(state, payload) {
    state.suppliersSelect = payload
  },

  SET_IMAGE_FILE(state, payload) {
    state.imageFile = payload
  },

  SET_ATTRIBUTES_SELECT(state, payload) {
    state.attributesSelect = payload
  },

  SET_CATEGORY(state, payload) {
    state.category = payload
  },

  SET_APP(state, payload) {
    state.app = payload
  },

  SET_ADDRESS_STREET(state, payload) {
    state.addressStreet[payload.id] = payload
  },

  SET_ADDRESS_CITY(state, payload) {
    state.addressCity[payload.id] = payload
  },

  SET_VENDOR(state, payload) {
    state.vendor = payload
  },

  SET_SUPPLIER(state, payload) {
    state.supplier = payload
  },

  SET_ROLES_SELECT(state, payload) {
    state.rolesSelect = payload
  },

  SET_FILE(state, payload) {
    state.file = payload
  },

  SET_IMPORT(state, payload) {
    state.import = payload
  },

  SET_OPTIONS_SELECT(state, payload) {
    state.optionsSelect = payload
  },

  SET_CATEGORY_UPDATE(state, payload) {
    const category = state.category_update.find((v) => v.locale === payload.locale)

    if (category) {
      state.category_update = state.category_update.map((x) => (x.locale === payload.locale ? { ...x, name: payload.name } : x))
    } else {
      state.category_update.push({
        locale: payload.locale,
        name: payload.name,
        active: payload.active
      })
    }
  },

  SET_PRODUCT_UPDATE(state, payload) {
    const product = state.product_update.find((v) => v.locale === payload.locale)

    if (product) {
      state.product_update = state.product_update.map((x) =>
        x.locale === payload.locale ? { ...x, name: payload.name, description: payload.description } : x
      )
    } else {
      state.product_update.push({
        locale: payload.locale,
        name: payload.name,
        description: payload.description
      })
    }
  },

  SET_ORDERS(state, payload) {
    state.orders = payload
  },

  SET_MESSAGES(state, payload) {
    state.messages = payload
  },

  SET_MESSAGE(state, payload) {
    state.message = payload
  },

  SET_THREADS(state, payload) {
    state.threads = payload
  },

  SET_ORDER(state, payload) {
    state.order = payload
  },

  SET_CATEGORIES(state, payload) {
    state.categories = payload
  },

  SET_APPS(state, payload) {
    state.apps = payload
  },

  SET_PRODUCTS(state, payload) {
    state.products = payload
  },

  SET_IMPORTED_PRODUCTS(state, payload) {
    state.importedProducts = payload
  },

  SET_IMPORTED_PRODUCTS_TOTAL(state, payload) {
    state.importedProductsTotal = payload
  },

  SET_PRODUCTS_SELECT(state, payload) {
    state.productsSelect = payload
  },

  SET_PRODUCT(state, payload) {
    state.product = payload
  },

  SET_TRAINING(state, payload) {
    state.training = payload
  },

  SET_TRAININGS(state, payload) {
    state.trainings = payload
  },

  SET_VENDOR_LIST(state, payload) {
    state.vendorList = payload
  },

  SET_SUPPLIERS_LIST(state, payload) {
    state.suppliersList = payload
  },

  SET_OPTION(state, payload) {
    state.option = payload
  },

  SET_USER_ADMIN(state, payload) {
    state.user_admin = payload
  },

  SET_USERS(state, payload) {
    state.users = payload
  },

  SET_PERMISSIONS(state, payload) {
    state.permissions = payload
  },

  SET_PERMISSIONS_MODEL(state, payload) {
    state.permissionsModel = payload
  },

  SET_CATEGORIES_MODEL(state, payload) {
    state.categoriesModel = payload
  },

  SET_ATTRIBUTE_MODEL(state, payload) {
    state.attributesModel = payload
  },

  SET_OPTIONS_MODEL(state, payload) {
    state.optionsModel = payload
  },

  SET_PERMISSIONS_SELECT(state, payload) {
    state.permissionsSelect = payload
  },

  SET_CATEGORIES_SELECT(state, payload) {
    state.categoriesSelect = payload
  },

  SET_IS_LOADING(state, payload) {
    state.isLoading = payload
  },

  SET_QUESTIONS(state, payload) {
    state.questions = payload
  },

  SET_B_TRAININGS(state, payload) {
    state.bTrainings = payload
  },

  SET_B_TRAINING(state, payload) {
    state.bTraining = payload
  },

  SET_B_TRAINING_TYPES(state, payload) {
    state.bTrainingTypes = payload
  },

  SET_B_TRAINING_COSTS(state, payload) {
    state.bTrainingCosts = payload
  },
  SET_B_TRAINING_DETAILS(state, payload) {
    state.bTrainingsDetails = payload
  },
  SET_PAGINATION_ACTIVITY(state, payload) {
    state.paginationActivity = payload
  },

  SET_PAGINATION_ACTIVITY_GLOBAL(state, payload) {
    state.paginationGlobalActivity = payload
  },

  SET_GROUPS_MODEL(state, payload) {
    state.groupsModel = payload
  },
  SET_PARENT_ATTRIBUTES(state, payload) {
    state.parentAttributes = payload
  },
  SET_GROUPS(state, payload) {
    state.groups = payload
  },
  SET_PARENT_GROUPS(state, payload) {
    state.parentGroups = payload
  },
  SET_GROUP(state, payload) {
    state.group = payload
  },
  SET_SLIDERS(state, payload) {
    state.slides = payload
  },
  SET_SLIDE(state, payload) {
    state.slide = payload
  },
  SET_TEXT_PAGES(state, payload) {
    state.textPages = payload
  },
  SET_TEXT_PAGE(state, payload) {
    state.textPage = payload
  },
  SET_SETTING(state, payload) {
    state.setting = payload
  },
  SET_FEATURED_PRODUCTS(state, payload) {
    state.featured_products = payload
  },
  SET_TOAST(state, payload) {
    if (payload) {
      state.toast.push(payload)
    } else {
      state.toast = []
    }
  },
  SET_THREADS_BY_SLUG(state, payload) {
    let ids = new Set(state.threadsBySlug.map((d) => d.id))

    state.threadsBySlug = [
      ...state.threadsBySlug,
      ...payload.data
        .map((item) => {
          return {
            ...item,
            slug: payload.slug
          }
        })
        .filter((d) => !ids.has(d.id))
    ]
  },
  SET_THREADS_USERS(state, payload) {
    state.threadsUsers = payload
  },
  SET_STATS(state, payload) {
    state.stats = payload
  },
  SET_TRAINING_RESULTS(state, payload) {
    state.trainingResults = payload
  },
  SET_B_TRAINING_RESULTS(state, payload) {
    state.bTrainingResults = payload
  },
  SET_EXTERNAL_TRAINING_RESULTS(state, payload) {
    state.externalTrainingResults = payload
  },
  SET_HUNTER_RESULTS(state, payload) {
    state.hunterResults = payload
  },
  SET_UBERALL_RESULTS(state, payload) {
    state.uberallResults = payload
  },
  SET_NEWS_FEEDS(state, payload) {
    state.newsFeeds = payload
  },
  SET_NEWS_FEED(state, payload) {
    state.newsFeed = payload
  },
  SET_WAREHOUSES(state, payload) {
    state.warehouses = payload
  },
  SET_WAREHOUSES_COUNT(state, payload) {
    state.warehouseCount = payload
  },
  SET_SALES_PROMOTIONS(state, payload) {
    state.salesPromotions = payload
  },
  SET_MENU_VISIBLE(state, payload) {
    state.menuVisible = payload
  },
  SET_HUNTER_DETAILS(state, payload) {
    state.hunterDetails = payload
  },
  SET_HUNTER_DETAIL(state, payload) {
    state.hunterDetail = payload
  },
  SET_DEALERS(state, payload) {
    state.dealers = payload
  },
  SET_WHOLESALERS(state, payload) {
    state.wholesalers = payload
  },
  SET_USER_ORDERS(state, payload) {
    state.userOrders = payload
  },
  SET_BANK_STATEMENT(state, payload) {
    state.bankStatement = payload
  },
  SET_LOGIN_HISTORY(state, payload) {
    state.loginHistory = payload
  },
  SET_LOGINS_HISTORY(state, payload) {
    state.loginsHistory = payload
  },
  SET_DELETED_USERS(state, payload) {
    state.detetedUsers = payload
  },
  SET_PRICES(state, payload) {
    state.prices = payload
  },
  SET_PRICE(state, payload) {
    state.price = payload
  },

  SET_PRICE_CATEGORIES(state, payload) {
    state.priceCategories = payload
  },

  SET_PRICE_LIST(state, payload) {
    state.priceList = payload
  },

  SET_SINGLE_PRICE_LIST(state, payload) {
    state.singlePriceList = payload
  },

  SET_STAFFS(state, payload) {
    state.staffs = payload
  },
  SET_REQUEST_STATS(state, payload) {
    state.requestStats = payload
  },

  SET_INACTIVE_USERS(state, payload) {
    state.inactiveUsers = payload
  },

  SET_CS_NUMBERS_TO_APPROVE(state, payload) {
    state.csNumberToApprove = payload
  },

  SET_MESSAGE_STATUS_MATCH(state, payload) {
    state.messageIbanStatus = payload
  },

  SET_STATUS_MATCH(state, payload) {
    state.csNumberToApprove = payload
  },

  SET_ADMIN_ROLE(state, payload) {
    state.admin_role = payload
  },

  SET_COMPLAINTS(state, payload) {
    state.complaints = payload
  },

  SET_COMPLAINT(state, payload) {
    state.complaint = payload
  },

  SET_CERTIFICATE_TEXTS(state, payload) {
    state.certificateTexts = payload
  },
  SET_ACTIVITIES(state, payload) {
    state.activities = payload
  },

  SET_ACTIVITIES_GLOBAL(state, payload) {
    state.activitiesGlobal = payload
  },

  SET_ACTIVITY_FILTERS(state, payload) {
    state.activityFilters = payload
  },

  SET_PAGINATION(state, payload) {
    state.pagination = payload
  },

  SET_BOOSTERS_LIST(state, payload) {
    state.boostersList = payload
  },

  SET_INACTIVE_BOOSTERS(state, payload) {
    state.inactiveBoosters = payload
  },

  SET_BOOSTERS_PREVIEW(state, payload) {
    state.boostersPreview = payload
  },

  SET_USER_BOOSTER_SELECT(state, payload) {
    state.userBoosterSelect = payload
  },

  SET_TRAINING_FILTERS(state, payload) {
    state.trainingFilters = payload
  },

  SET_PROMO_FILTERS(state, payload) {
    state.promoFilters = payload
  },

  SET_MENU_FILTERS(state, payload) {
    state.menuList = payload
  },

  SET_MEDIA_CATEGORIES(state, payload) {
    state.mediaCategories = payload
  },
  SET_MEDIAS(state, payload) {
    state.medias = payload
  },
  SET_MEDIA_PAGE(state, payload) {
    state.mediaPage = payload
  },
  SET_MEDIA_PAGE_FILES(state, payload) {
    state.mediaPageFiles = payload
  },
  SET_WHOLESALER_MANAGEMENT(state, payload) {
    state.wholesalerManagements = payload
  },
  SET_DEFAULT_BANNERS(state, payload) {
    state.defaultBanners = payload
  },
  SET_POINT_SETTINGS(state, payload) {
    state.pointSettings = payload
  },
  SET_INITIAL_USER_ROLE(state, payload) {
    state.initialUserRole = payload
  },
  SET_SHOP_LIMITS(state, payload) {
    state.shopLimits = payload
  },
  SET_MEDIA_FILES(state, payload) {
    state.mediaFiles = payload
  },
  SET_TOTAL_MEDIA_FILES(state, payload) {
    state.totalMediaFiles = payload
  },
  SET_VOUCHER_SELECT(state, payload) {
    state.voucherSelect = payload
  },
  SET_VOUCHERS(state, payload) {
    state.vouchers = payload
  },
  SET_BROCHURE_CATEGORIES(state, payload) {
    state.brochureCategories = payload
  },
  SET_BROCHURES(state, payload) {
    state.brochures = payload
  },
  SET_BROCHURE(state, payload) {
    state.brochure = payload
  },
  SET_BROCHURE_FILES(state, payload) {
    state.brochureFiles = payload
  },
  SET_TOTAL_BROCHURE_FILES(state, payload) {
    state.totalBrochureFiles = payload
  },
  SET_FILTERS_USER(state, payload) {
    state.filterUsers = payload
  },
  SET_MAIN_BOXES(state, payload) {
    state.mainBoxes = payload
  },
  SET_MAIN_BOX(state, payload) {
    state.mainBox = payload
  },
  SET_SURVEYS(state, payload) {
    state.surveys = payload
  },
  SET_SURVEYS_ACTIVATED(state, payload) {
    state.surveysActivated = payload
  },
  SET_SURVEY_GROUPS(state, payload) {
    state.survey_groups = payload
  },
  SET_USER_APPS(state, payload) {
    state.userApps = payload
  },
  SET_INITIAL_PARENT_ATTRIBUTES(state, payload) {
    state.initialParentAttributes = payload
  },
  SET_PARENT_ATTRIBUTE_PENDING(state, payload) {
    state.parentAttributesPending = payload
  },
  // tenancy
  SET_IS_LITE_VERSION(state, value) {
    state.isLiteVersion = value
  },
  SET_AVAILABLE_MODULES(state, modules) {
    state.availableModules = modules
  },
  SET_TENANCY_ENABLED(state, value) {
    state.tenancyEnabled = value
  },
  SET_TENANT_ID(state, tenantId) {
    state.tenantId = tenantId
  },
  SET_USERS_PENDING(state, tenantId) {
    state.usersPending = tenantId
  },
  UPDATE_APPS(state, updatedApps) {
    state.apps = updatedApps
  },
  UPDATE_APP_ACTIVE_STATUS(state, { id }) {
    const app = state.apps.find((app) => app.id === id)
    if (app) {
      app.enabled = !app.enabled
    }
  },
  SET_ABOUT_LIST(state, payload) {
    state.abouts = payload
  },
  SET_ABOUT(state, payload) {
    state.about = payload
  },
  SET_CONTACT_SERVICES(state, payload) {
    state.contactServices = payload
  },
  SET_CONTACT_SERVICE(state, payload) {
    state.contactService = payload
  }
}

export { mutations }
