import TokenService from './storage.service'
import router from '../router'
import axios from 'axios'
import store from '@/store/index'
import i18n from '@/plugins/i18n'
import { toast } from 'vue3-toastify'
import { TENANCY_SETTINGS } from '@/config'

const LANG = localStorage.getItem('lang')
const currentLocale = localStorage.getItem('currentLocale')

const ApiService = {
  init(baseURL) {
    const tenantId = store.getters.tenantId
    const tenancyEnabled = store.getters.tenancyEnabled

    if (tenancyEnabled) {
      axios.defaults.headers.common['X-Tenant'] = tenantId
      localStorage.setItem('currentCountry', tenantId)
      baseURL = baseURL.replace('{tenant}', tenantId.toLowerCase())
      axios.defaults.headers.common['Accept-Language'] = LANG
    } else {
      axios.defaults.headers.common['Accept-Language'] = currentLocale
      axios.defaults.headers.common['GW-Language'] = LANG
    }

    axios.defaults.baseURL = baseURL

    this.setupInterceptors()
  },

  setupInterceptors() {
    axios.interceptors.request.use((config) => {
      store.dispatch('addPending', true)
      config.headers.common.Authorization = `Bearer ${TokenService.getToken()}`
      return config
    })

    axios.interceptors.response.use(
      (response) => {
        toast.clearAll()
        store.dispatch('addPending', false)
        if (response.config.method !== 'get') {
          this.showToast('success', 'message.toastMessages.success')
        }
        return response
      },
      (error) => {
        store.dispatch('addPending', false)
        this.showToast('error', 'message.toastMessages.error')
        if (error.response && error.response.status === 401) {
          router.push('/error')
        }
        return Promise.reject(error)
      }
    )
  },

  showToast(type, messageKey) {
    toast(i18n.global.t(messageKey), {
      type,
      theme: 'colored',
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.TOP_RIGHT
    })
  },

  setHeader(contentType = 'application/json') {
    axios.defaults.headers.common['Content-Type'] = contentType
    axios.defaults.withCredentials = true
  },

  removeHeader() {
    delete axios.defaults.headers.common['Authorization']
    delete axios.defaults.headers.common['Content-Type']
  },

  csrfCookie() {
    return axios.get('/sanctum/csrf-cookie')
  },

  request(method, resource, data = null, headers = {}) {
    const urlPrefix = TENANCY_SETTINGS.TENANCY ? '/api/v1/tenancy/' : `/api/v1/${LANG}/`
    const url = urlPrefix + resource
    return axios({ method, url, data, headers })
  },

  get(resource) {
    return this.request('get', resource)
  },

  post(resource, data, headers) {
    return this.request('post', resource, data, headers)
  },

  put(resource, data) {
    return this.request('put', resource, data)
  },

  delete(resource) {
    return this.request('delete', resource)
  },

  patch(resource, data) {
    return this.request('patch', resource, data)
  }
}

export default ApiService
